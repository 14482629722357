'use client';

import React from 'react';
import { useRouter } from 'next/navigation';
import { useAmplifyAuthContext } from '@/contexts';
import { ScreenLoading } from '@/components';

export default function AuthScreen() {
    const router = useRouter();
    const { amplifyUser } = useAmplifyAuthContext();

  React.useEffect(() => {
    if (!amplifyUser) {
      router.push('/login');
    } else {
      router.push('/users');
    }
  }, [amplifyUser, router]);

  return <ScreenLoading message="Redirecting after authentication" />;
}
