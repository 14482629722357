import { TextField } from '@mui/material';
import { Stack } from '@mui/system';
import * as React from 'react';
import { FieldProp } from 'react-typed-form';

import { ErrorList } from '../components';

type PassthroughProps = Omit<
  React.ComponentProps<typeof TextField>,
  'disabled' | 'onChangeText' | 'value'
>;

type Props = PassthroughProps & {
  field: FieldProp<string | null>;
  forceDisabled?: boolean;
  onChangeText?: (text: string) => void;
};

export default function FieldText({
  field,
  forceDisabled,
  onChangeText,
  label,
  ...rest
}: Props) {
  return (
    <Stack spacing={1}>
      <TextField
        value={field.value || ''}
        disabled={forceDisabled || field.isLoading}
        label={label !== undefined ? label : field.label}
        onChange={(ev) => {
          const text = ev.target.value;
          if (text.trim() === '') {
            field.handleValueChange(null);
            return;
          }

          if (onChangeText) {
            onChangeText(text);
            return;
          }

          field.handleValueChange(text);
        }}
        {...rest}
      />
      <ErrorList errors={field.lastErrorList} />
    </Stack>
  );
}
