'use client';

import { useAmplifyAuthContext } from '@/contexts';
import { FieldTextRequired, FormWrap, SubmitButton } from '@/form';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Stack,
} from '@mui/material';
import { useRouter } from 'next/navigation';
import React from 'react';
import { useForm } from 'react-typed-form';

export default function LoginScreen() {
  const { signIn } = useAmplifyAuthContext();

  const router = useRouter();

  const form = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values, form) => {
      try {
        form.setLoading(true);
        await signIn(values.email, values.password);
        router.push('/');
      } catch (err) {
        if (err instanceof Error) {
          form.addSubmitError('_form', err.message);
        }
      } finally {
        form.setLoading(false);
      }
    },
  });

  return (
    <Container
      sx={{
        width: '100vw',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ width: '100%', paddingTop: '10%' }} maxWidth="sm">
        <Card>
          <CardHeader title="Login" />
          <CardContent>
            <FormWrap {...form}>
              <Stack spacing={3}>
                <FieldTextRequired field={form.getField('email')} />
                <FieldTextRequired
                  field={form.getField('password')}
                  type="password"
                />
                <SubmitButton {...form} label="Login" />
              </Stack>
            </FormWrap>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
